import React, {useEffect, useState} from "react";
import badge from '../img/ICML_Illustration_Badge.svg';
import {Row, Col, Button} from 'react-bootstrap';
import {useLocation, useNavigate} from "react-router-dom";
import ScaleText from "react-scale-text";

const Validate = () =>{

    const [userData, setUserData] = useState()
    const location = useLocation();
    const navigation = useNavigate();


    useEffect(() => {
        if(location.state?.data === null || location.state?.data === undefined){
            navigation("/error")
        } else {
            setUserData(location.state?.data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataAreValid = (e) => {
        e.preventDefault()
        navigation("/loading", {state: {code: location.state?.code}})
    }

    const dataAreNotValid = (e) => {
        e.preventDefault()
        navigation("/error")
    }

    return(
        <div className={'all-validation'}>
            <Row className={'w-100'}>
                <Col xs={7}>
                    {userData &&
                        <div className={'position-relative'}>
                        <img src={badge} alt={'alert icon'} className={'img-fluid'} />
                        <div className={'position-absolute info-badge'} >
                            <ScaleText minFontSize={42} maxFontSize={76}>
                                <p>{userData.firstname[0]}{userData.middlename ? ` ${userData.middlename} ` : " "}{userData.lastname}</p>
                            </ScaleText>
                            <p>{userData.city}</p>
                            <p className={'mb-0'}>{userData.country}</p>
                        </div>
                    </div>
                    }
                </Col>
                <Col xs={5}>
                    <div className={'h-100 d-flex flex-column'}>
                        <Row className={'mb-4'}>
                            <Col>
                                <div className={'item-box-border-red confirm-information p-5'}>
                                    <p><span className={'text-primary'}>Please confirm</span> that the data displayed here are correct</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className={'mt-auto button-validate'}>
                            <Col>
                                <Button variant="primary" onClick={(e) => dataAreValid(e)} className={'w-100 py-5'}>Yes</Button>
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={(e) => dataAreNotValid(e)} className={'w-100 py-5'}>No</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>


    );
}

export default Validate;